.react-form-builder .react-form-builder-toolbar {
    margin-top: 0px !important;
    width: 20% !important;
}

.react-form-builder.clearfix>div {
    display: flex;
}

.react-form-builder .react-form-builder-toolbar h4 {
    display: none;
}

.react-form-builder-form .row {
    padding: 0px !important;
    border: none !important;
    /* border-radius: 10px; */
    margin-top: 0px !important;
}


.react-form-builder-form .rfb-item {
    padding: 0px !important;
}

.react-form-builder-form .rfb-item label span:first-of-type {
    font-size: 16px !important;
    font-family: Public Sans !important;
    font-weight: 400 !important;
    padding: 0px !important;
}

.react-form-builder-form .form-control {
    font-size: 16px !important;
    font-family: Public Sans !important;
    font-weight: 400 !important;
    color: #2d2c2c !important;
    background: #FFFFFF !important;
}

.react-form-builder-form .form-control input:focus {
    outline: none !important;
    box-shadow: none !important;
}



.react-form-builder-form .react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__input-container {
    position: relative;
}

.react-form-builder-form .static {
    color: black !important;
    font-size: 18px !important;
    font-family: Public Sans !important;
    font-weight: 700 !important;
    /* text-transform: capitalize !important; */
}

.react-form-builder-form .react-datepicker__input-container::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust this value according to your design */
    transform: translateY(-50%);
    width: 20px;
    /* Adjust the width and height according to your icon size */
    height: 20px;
    background-image: url('../../assets/images/icons/calendar.png');
    /* URL to your calendar icon */
    background-size: cover;
}

.form-group {
    position: relative;
}

.info-icon {
    bottom: 55%;
    color: #828282;
    position: absolute;
    top: 55%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer !important;
}

.form-control {
    padding-right: 50px;
    /* Adjust this value to accommodate the icon */
}


select:disabled {
    appearance: none
}

.SortableItem hr {
    padding-bottom: 0.6rem;
}

.react-form-builder-preview {
    max-height: 33.5rem !important;
    min-height: 33.5rem !important;
    overflow-y: auto;
}

.react-form-builder-toolbar {
    max-height: 33.5rem;
    overflow-y: auto;
}

.form-group .custom-control-input {
    z-index: 1 !important;
}

.react-form-builder-form .react-datepicker__close-icon {
    display: none;
}

.Sortable [class*="col-"]>div {
    height: 100% !important;
}



.react-form-builder-form input[type="date"],
.react-form-builder input[type="date"] {
    padding-bottom: 1.6rem !important;
    width: 99.8%;
    border: 1px solid #ced4da;
    padding: 5px;
    border-radius: 4px;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545 !important;
}
.react-form-builder-form .rfb-item label span {
    font-weight: bold !important;
    padding: 0 10px !important;
}
.react-form-builder-form .rfb-item label span:nth-of-type(2) {
    display: inline-block !important;
    padding: .25em .4em !important;
    font-size: 75% !important;
    font-weight: 700 I !important;
}

.circle-icon {
    margin-right: 15px;
    margin-top: 6px;
}

.react-form-builder-form input:disabled {
    background-color: #f0f1f5 !important;
}

.react-form-builder-form textarea:disabled {
    background-color: #f0f1f5 !important;
}
.react-form-builder-form select:disabled {
    background-color: #f0f1f5 !important;
}


@media(max-width: 767px) {
    .react-form-builder .react-form-builder-preview {
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
        order: 2;
    }

    .react-form-builder .react-form-builder-toolbar {
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
        order: 1;
        bottom: 0 !important;
        margin-bottom: 20px;
    }

    .react-form-builder.clearfix>div {
        flex-direction: column;
    }

}

@media(max-width: 1000px) {
    .sub-code {
        font-size: 10px;
    }
}