@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import "@fontsource/roboto";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 12px;
}


@media print {
  @page {
    margin:8mm;
  }
}

.username-title{
  text-transform: capitalize;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #171928;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #171928;
}


body {
  font-family: "Roboto" !important;
}

.rounded-xl {
  border-radius: 0.55rem;
}

.notched-outline-none {
  border-style: none !important;
}


/* ////////////////////////   progress ////////////////////////////// */
.CircularProgressbar .CircularProgressbar-path {
  stroke-linecap: square !important;
}

/* ////////////////////////////////////////////////////////////////////// */

.divider-border {
  border-color: #ffffff1a !important;
}

.circular-loader {
  border: 2px solid #f3f3f3;
  /* Light grey */
  border-top: 2px solid #2F5597;
  /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: auto;
}
/* Hide the image by default */
.print-only {
  display: none;
}
.apexcharts-legend-series {
  pointer-events: none;
}
/* Show the image when printing */
@media print {
  .print-only {
    display: block;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disable-add-visit-btn{
  cursor: not-allowed !important;
  opacity: 0.7 !important;
}
.disable-add-visit-btn button{
  cursor: not-allowed !important;
  /* opacity: 0.2 !important; */
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
 
.description-trial textarea[name="description"] {
  min-height: 43px !important;
  /* height: auto !important; */
  border-radius: 5px;
  padding: 10px 14px 0 12px !important;
}
.description-trial textarea[name="description"]:focus {
  outline: none !important;
}
.profile-nav {
  background: transparent !important;
}
.MuiList-root .css-17f7sho-MuiButtonBase-root-MuiMenuItem-root.Mui-disabled{
  opacity: 0.9 !important;
}
.modalInner {
  max-height: calc(100vh - 130px);
  /* overflow: auto !important; */
}

/* @media (max-width: 1367px) {
  .modalInner {
    max-height: calc(100vh - 200px);
    overflow: auto !important;
  }
} */

.css-hhfl35 {
  display: none;
}

.breakMain {
  word-break: break-all;
  width: 50%;
}

button:focus {
  outline: none !important;
}

.MuiOutlinedInput-root.Mui-disabled {
  opacity: 2;
  -webkit-text-fill-color: #5c5959;
}

.MuiAlert-icon {
  align-self: center;
}

.edit-trial-detail-form {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.edit-trial-detail-form .edit-column-input {
  min-width: 32%;
  padding-bottom: 25px;
}

.edit-trial-detail-form .edit-column-input:nth-child(4),
.edit-trial-detail-form .edit-column-input:nth-child(5),
.edit-trial-detail-form .edit-column-input:nth-child(6),
.edit-trial-detail-form .edit-column-input:nth-child(7) {
  min-width: 23.5%;
  padding-bottom: 0;
}

.phase-list-card {
  max-height: 100vh;
  overflow-y: auto;
}
.hide-label label{
  display: none !important;
} 
.date-range-container {
  position: relative;
}

.date-range-picker {
  position: absolute;
  top: 100%;
  z-index: 1;
}
.date-input fieldset{
  border-color: #eee;
    border-radius: 11px;
}
.date-input{
  background-color: white;
  border-color: #ccc;
  border-radius: 10px;
  border: 1px solid #e5e7eb !important;
}
.date-input input{
  height: 1.77375em;
}
.calenderBtn{
  background: #fff;
    padding: 10px;
}
.date-input fieldset{
  border: none !important;
}
.right-area {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.right-area::-webkit-scrollbar {
  display: none;
}
@media(min-width:768px) and (max-width:1100px) {
  .rdrDefinedRangesWrapper{
    display: none;
  }
  .rdrMonth {
    width: 20.667em !important;
}
}

@media(max-width:768px) {
  .custom-range-picker {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .newAdmin{
    flex-direction: column;
    align-items: flex-start;
  }
  .newAdmin span{
    margin-bottom: .5rem;
  }
  .filtered-div{
    flex-direction: column;
    width: 100%;
  }
  .filtered-div .select-div{
    width: 100%;
    margin-top: 1rem;
  }
  .filtered-div #select-id{
    margin-right: 0;
    margin-top: 0rem;
  }
  .filtered-div .filter-by-any{
    width: 100%;
    margin: 10px 0;
  }
  .filtered-div .filter-by-any span{
    margin-bottom: 0;
  }
}

@media(max-width:640px) {
  .custom-range-picker .rdrMonths {
    flex-direction: column;
  }
  .rdrDefinedRangesWrapper{
    display: none;
  }
  .newAdmin {
    align-items: flex-start !important;
    flex-direction: column !important;
  }

  .nav-lists ul .react-tabs__tab {
    margin-right: 5px;
  }

  .filtered-div {
    flex-wrap: wrap;
  }

  .filtered-div .filter-by-any {
    margin: 10px 0;
  }

  .date-range-picker-main {
    right: 5%;
    top: 176px;
  }

  .filtered-div {
    margin-top: .5rem;
  }
}
@media(max-width: 767px) {
  .phase-list-card {
    max-height: 300px;
    overflow-y: auto;
  }
}

@media(max-width: 1365px) {
  .edit-trial-detail-form .edit-column-input {
    min-width: 31%;
  }

  .edit-trial-detail-form .edit-column-input:nth-child(4),
  .edit-trial-detail-form .edit-column-input:nth-child(5),
  .edit-trial-detail-form .edit-column-input:nth-child(6),
  .edit-trial-detail-form .edit-column-input:nth-child(7) {
    min-width: 22.5%;
  }
}

@media(min-width: 768px) and (max-width: 1034px) {
  .edit-trial-detail-form .edit-column-input {
    min-width: 48% !important;
  }

}

@media(max-width: 767px) {
  .edit-trial-detail-form .edit-column-input {
    min-width: 100% !important;
  }

  .edit-trial-detail-form .edit-column-input:nth-child(4),
  .edit-trial-detail-form .edit-column-input:nth-child(5),
  .edit-trial-detail-form .edit-column-input:nth-child(6),
  .edit-trial-detail-form .edit-column-input:nth-child(7) {
    padding-bottom: 25px !important;
    width: 100% !important;
  }
  .customQueryStepperInner{
    flex: 1;
  }
  .customQueryStepperInner p{
    flex: 1;
  }
}


.mainDiv {
  width: auto !important;
  max-width: 100% !important;
}


.defsult-stroke-cricle .apexcharts-pie circle {
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 10;
}

.customSvgMain svg {
  width: 100%;
  max-width: 100%;
}

span.react-datepicker__aria-live {
  display: none;
}

.relative {
  position: relative;
}

.update-trial-btn {
  position: absolute;
  bottom: -20px;
  left: 0;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.disabled-btn:disabled {
  background: #dee2e6 !important;
  cursor: not-allowed;
}



.svgIconDiv svg{
  width: 44px !important;
  height: 44px !important;
}
.CircularProgressdownload SVG{
  width: 30px !important;
  height: 30px !important;
  color: #FFF  !important;
}
.svgIconDiv .overlay, .downloadDiv{
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.svgIconDiv:hover .overlay, .svgIconDiv:hover .downloadDiv{
  opacity: 1;
}


/* loader css */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
  text-align: center;
  line-height: 40px;
}
.scroll-loader .css-1aktm7a-MuiCircularProgress-root{
  width: 20px !important;
  height: 20px !important;
}
.input-focus-removed fieldset{
  border: none !important;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media(min-width: 1024px) and (max-width: 1230px){
  .chatdate{
    font-size: 10px;
  }
  .user-chatlist{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .thumbnailuser{
    width: 27px;
    height: 27px;
  }
}
/* disabled button css */
.disableButton {
  background-color: rgba(0, 123, 255, 1) !important;
  background: rgba(0, 123, 255, 1) !important;
  opacity: .6;
  color: white !important;
}


.infoCardDiv span{
  white-space: normal !important;
  padding-left: 8px;
  padding-right: 8px;
}