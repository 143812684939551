.customChartMain .apexcharts-legend.apx-legend-position-right {
    top: 8px !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: auto !important;
    max-width: 140px !important;
}
.chart-scrollbar.customChartMain .apexcharts-legend.apx-legend-position-right {
    justify-content: start;
}

.customChartMain .apexcharts-legend.apx-legend-position-right .apexcharts-legend-text {
    font-size: 12px !important;
}

.customChartMain .apexcharts-legend.apx-legend-position-right .apexcharts-legend-series {
    margin-bottom: 5px !important;
}

.customChartMain svg {
    min-width: 350px !important;
}
.apexcharts-legend{
    overflow-x: hidden !important;
}
@media (max-width:1300px) and (min-width: 991px) {
    .customChartMain .apexcharts-legend.apx-legend-position-right .apexcharts-legend-text {
        font-size: 12px !important;
    }
}

@media (max-width:991px) and (min-width: 300px) {
    .customChartMain .apexcharts-legend.apx-legend-position-right .apexcharts-legend-text {
        font-size: 10px !important;
    }

    .commonDivCard {
        flex: 0 0 30% !important;
    }

    .customChartMain svg {
        min-width: auto !important;
    }

    .customChartMain .apexcharts-canvas {
        width: 280px !important;
    }

    .customChartMain .apexcharts-datalabel-label {
        font-size: 10px !important;
    }

    .customChartMain .apexcharts-datalabel-value {
        font-size: 18px !important;
    }
}

@media (min-width:800px) and (max-width: 1024px) {
    .commonDivCard {
        flex: 0 0 26% !important;
    }

    .customChartMain .apexcharts-canvas {
        width: 270px !important;
    }
}

.commonDivCard {
    flex: 1;
}

.commonDivCard:last-child::after {
    display: none !important;
}