/* border border-gray-300 hover:border-sky-400 focus:outline focus:outline-2 focus:outline-sky-200 p-[8.067px] rounded-[4px] bg-[${BgColor}] */
.react-datepicker__input-container {
    display: block !important;
    flex-direction: row-reverse;
    height: 100%;
    border-radius: 6px;
}


.react-datepicker__input-container:focus-within{
    border-color: black;
    border-radius: 6px;
}

#edit-trial-details-date-picker {
    display: block !important;
}

.react-datepicker__view-calendar-icon input{
   padding: 10.5px 14px 10.5px 12px !important
   
}

.react-datepicker__view-calendar-icon input::placeholder{
    color: #777777;    
    opacity: 100%;
 }

/* EDEDED */

.date-picker-custom-class{
    font-size:0.9rem;
}

.input::placeholder{
    color:"#777777"
}





.border-class {
    display: block !important;
}

/* styles.css */
.date-range-container {
    position: relative;
  }
  
  .date-range-picker {
    position: absolute;
    top: 100%;
    z-index: 1;
    right: 0;
  }
  .hide-legend .MuiOutlinedInput-notchedOutline legend {
    display: none;
  }